.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FFA300 !important;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #FFA300 !important;
}

.ant-tabs-ink-bar {
  background: #FFA300 !important;
}

.ant-tabs-tab:hover {
  color: #FFA300 !important;
}

.ant-tabs-tab {
  font-weight: 500;
}

.MuiTimelineDot-root {
  background-color: #FFA300 !important;
  padding: 5px !important;
}

.ant-select-arrow {
  color: #FFFFFF !important;
}

.ant-tabs-bottom>.ant-tabs-nav:before,
.ant-tabs-bottom>div>.ant-tabs-nav:before,
.ant-tabs-top>.ant-tabs-nav:before,
.ant-tabs-top>div>.ant-tabs-nav:before {
  border: 0 !important;
}

.login .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: 'Montserrat' !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #212121 !important;
}

.login .ant-tabs-tab-btn {
  font-family: 'Montserrat' !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #8B8B8B !important;
}

.ant-input {
  height: 48px !important;
  border: 1px solid #DADADA !important;
  border-radius: 24px !important;
}

.ant-checkbox-wrapper {
  font-size: 16px !important;
  font-weight: 400;
  color: #212121;
}

.ant-breadcrumb {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.ms-TextField-fieldGroup {
  border: 1px solid #DDDDDD;
}

.ms-ComboBox::after {
  border: 1px solid #DDDDDD;
}

.ms-TextField--borderless .ms-TextField-fieldGroup {
  border: 0;
}

.ms-BasePicker-text {
  border: 1px solid #DDDDDD;
}

.ms-Nav-link {
  padding: 0px 8px !important;
}

.ms-Checkbox-checkbox {
  border: 1px solid #DDDDDD;
}

.ant-message {
  z-index: 9999999 !important;
}

.row-bold {
  color: #212121 !important;
  font-weight: 500;
}

.row-capitalize {
  text-transform: capitalize;
}

.ms-DetailsRow-cell {
  color: #212121 !important;
  padding-left: 16px;
}

.ms-DetailsHeader-cellTitle {
  padding-left: 16px;
}

.ms-Panel-commands {
  z-index: 9999993 !important;
}

.ms-FocusZone {
  padding-top: 0px;
}

.ms-DetailsList-contentWrapper {
  background-color: #ffffff;
}

.ms-TagItem {
  background-color: #ffffff;
}

.ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
  background-color: #0358A7 !important;
  border-color: #0358A7 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #0358A7 !important;
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: #0358A7 !important;
}

.ant-breadcrumb a:hover {
  color: #0358A7 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.ant-menu {
  color: #FFFFFF !important;
}

.whiteLeftPanel .ant-menu {
  color: #212121 !important;
}

.ant-menu-item-selected {
  color: #FFA300 !important;
}

.ant-menu-item:hover {
  color: #FFA300 !important;
}

.whiteLeftPanel .ant-menu-item:hover {
  color: #212121 !important;
}

.whiteLeftPanel .ant-menu-item-selected {
  color: #212121 !important;
}

.ant-menu-vertical .ant-menu-item {
  padding: 0 !important;
}

.fileUpload input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: flex;
  height: 32px;
  margin-top: 3px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
}

.custom-file-upload:hover {
  color: rgba(0, 0, 0, 0.85);
}

.customSlickLeft .slick-track {
  margin-left: 0;
  margin-right: 0;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: rgb(96, 94, 92) !important;
  font-size: 13px !important;
}

.ant-radio-inner::after {
  background-color: #FF6600 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #FF6600 !important;
}

.mainPadding {
  padding-left: 120px;
  padding-right: 120px;
}

.smallMenu {
  display: none !important;
}

.productContainer {
  width: 260px;
}

.productImage {
  width: 260px;
  height: 260px;
  transition-timing-function: ease-in;
  transition: 1s;
}

.productImage:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition-timing-function: ease-in;
  transition: 1s;
}

.fontMid {
  font-size: 14px;
}

.fontMidV2 {
  font-size: 14px;
}

.fontTitle {
  font-size: 16px;
}

.fontLarge {
  font-size: 18px;
}

.mainProdImage {
  width: 400px;
  height: 400px;
}

.similarProduct .ant-list-item {
  padding: 0px !important;
}

.similarProduct .ant-list-split .ant-list-item {
  border: 0px !important;
}

.similarImage {
  width: 100px;
  height: 100px;
}

.loadingImage {
  background: transparent url('./Images/loading.gif') center center no-repeat;
  background-size: 24px 24px;
}

.cartButton:hover {
  background: #FFAA13 !important;
}

.ant-divider-horizontal {
  margin: 16px 0px !important;
}

@media only screen and (max-width: 1600px) {
  .mainPadding {
    padding-left: 120px;
    padding-right: 120px;
  }
}

@media only screen and (max-width: 1200px) {
  .mainPadding {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media only screen and (max-width: 992px) {
  .mainPadding {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .mainPadding {
    padding-left: 16px;
    padding-right: 16px;
  }

  .mainMenu {
    display: none !important;
  }

  .smallMenu {
    display: flex !important;
  }
}


@media only screen and (max-width: 576px) {
  .mainPadding {
    padding-left: 16px;
    padding-right: 16px;
  }

  .mainMenu {
    display: none !important;
  }

  .smallMenu {
    display: flex !important;
  }
}

@media only screen and (max-width: 480px) {
  .mainPadding {
    padding-left: 16px;
    padding-right: 16px;
  }

  .mainMenu {
    display: none !important;
  }

  .smallMenu {
    display: flex !important;
  }

  .orderListImg {
    display: none !important;
  }

  .productContainer {
    width: 200px;
  }

  .productImage {
    width: 200px;
    height: 200px;
  }

  .fontMid {
    font-size: 12px;
  }

  .fontMidV2 {
    font-size: 10px;
  }

  .fontLarge {
    font-size: 14px;
  }

  .fontTitle {
    font-size: 12px;
  }

  .mainProdImage {
    width: 300px;
    height: 300px;
  }

  .similarProduct {
    font-size: 8px !important;
  }

  .similarImage {
    width: 40px;
    height: 40px;
  }
}